var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"login"}},[_c('a-row',[_c('a-col',{attrs:{"md":16,"sm":12}},[_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("./../assets/menu.svg"),"alt":"menu"}})])]),_c('a-col',{attrs:{"md":8,"sm":12}},[_c('div',{staticClass:"login-box"},[_c('h4',[_vm._v("LOGIN")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"login-form","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'email',
                {
                  rules: [
                    { required: true, message: 'Please input your email!' } ],
                } ]),expression:"[\n                'email',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your email!' },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Email"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Password!',
                    } ],
                } ]),expression:"[\n                'password',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input your Password!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"type":"password","placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Log in ")]),_c('br'),_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remember',
                {
                  valuePropName: 'checked',
                  initialValue: false,
                } ]),expression:"[\n                'remember',\n                {\n                  valuePropName: 'checked',\n                  initialValue: false,\n                },\n              ]"}]},[_vm._v(" Remember me ")]),_c('br'),_c('a',{staticClass:"login-form-forgot",attrs:{"href":"https://augusthost.com/contact#contact-form"}},[_vm._v(" Forgot password ")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }