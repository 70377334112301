import Vue from "vue";
import Router from "vue-router";
import NotFound from "@/components/NotFound";
import Login from "@/components/Login";
import Register from "@/components/Register";
import Home from "@/components/Home";
import Alias from "@/components/Alias";
import SMTP from "@/components/SMTP";
import MyProfile from "@/components/MyProfile";
import { auth } from "./../firebase";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      name: "Not Found",
      component: NotFound,
    },
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/aliases",
      name: "Aliases",
      component: Alias,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profile",
      name: "My Profile",
      component: MyProfile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/smtps",
      name: "SMTP",
      component: SMTP,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        requiresGuest: true,
      },
    },
  ],
});

window.document.title = "Domain Email Panel - Aliases";

// Nav Guard
router.beforeEach((to, from, next) => {
  if (from.name) {
    window.document.title = "Domain Email Panel - " + to.name;
  }

  // Check for requiresAuth guard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if NO logged user
    if (!auth.currentUser) {
      // Go to login
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      // Proceed to route
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    // Check if logged user
    if (auth.currentUser) {
      // Go to login
      next({
        path: "/",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      // Proceed to route
      next();
    }
  } else {
    // Proceed to route
    next();
  }
});

export default router;
