<template>
  <Layout v-if="plan === 'S' || plan === 'G'">
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>SMTPs</a-breadcrumb-item>
    </a-breadcrumb>
    <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
      <h1>SMTP credentials</h1>
      <p>
        <small
          >SMTP သည်မိမိတို့ Gmail နှင့် email server ကိုချိတ်ဆက်ပေးသောကြားခံ
          account ဖြစ်ပါသည်</small
        >
      </p>

      <a-divider />

      <div v-if="plan === 'G'" id="gold">
        <div><strong>Server</strong> - smtp.improvmx.com</div>
        <div><strong>Port</strong> - 587</div>
        <div><strong>Connection</strong> - TLS</div>
        <p id="formTop" :style="{ textAlign: 'right' }">
          <a-button
            type="primary"
            @click="addNew"
            v-if="!editing || !adding"
            icon="plus"
            style="margin: 16px 0"
          >
            Add New
          </a-button>
        </p>

        <a-input
          @keyup="smtpsSearch"
          v-model="searchVal"
          placeholder="Searchy by Alias"
        />
        <a-divider />

        <form
          id="smtpForm"
          v-if="editing || adding"
          :style="{ maxWidth: '300px' }"
        >
          <a-input placeholder="Username" v-model="formSMTP.username" />
          <small>@{{ domain }}</small>
          <div class="divider"></div>
          <a-input placeholder="Password" v-model="formSMTP.password" />
          <a-button size="small" @click="insertGeneratePass"
            >Generate Password</a-button
          >
          <br />
          <a-button
            @click="
              editing = false;
              adding = false;
            "
            icon="close"
            style="margin: 16px 16px 16px 0"
          >
            Cancel
          </a-button>
          <a-button
            @click="submitAlias"
            type="primary"
            icon="edit"
            style="margin: 16px 0"
          >
            {{ formType == "add" ? "Add" : "Change" }}
          </a-button>
        </form>

        <a-list
          class="demo-loadmore-list"
          :loading="loading"
          item-layout="horizontal"
          :data-source="filteredSmtps.length > 0 ? filteredSmtps : smtps"
          v-if="!editing || !adding"
        >
          <div
            v-if="showLoadingMore"
            slot="loadMore"
            :style="{
              textAlign: 'center',
              marginTop: '12px',
              height: '32px',
              lineHeight: '32px',
            }"
          >
            <a-spin v-if="loadingMore" />
            <!-- <a-button v-else @click="onLoadMore">
                    loading more
                </a-button> -->
          </div>
          <a-list-item slot="renderItem" slot-scope="smtp, index">
            <a slot="actions" @click="editAlias(smtp.username)">edit</a>
            <a slot="actions" @click="openDeleteModal(smtp.username)">delete</a>
            <!-- 
                <a-list-item-meta
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                >
                </a-list-item-meta> -->
            <div>
              {{ index + 1 }}.
              <strong>{{ smtp.username }}@{{ domain }}</strong>
            </div>
          </a-list-item>
        </a-list>
      </div>

      <div v-if="plan === 'S'" id="silver">
        <table class="credential-table">
          <tbody>
            <tr>
              <td>Server</td>
              <td><a-input :value="user ? user.server : ''"></a-input></td>
            </tr>
            <tr>
              <td><b>Port</b></td>
              <td><a-input :value="587"></a-input></td>
            </tr>
            <tr>
              <td><b>SMTP user</b></td>
              <td><a-input :value="user ? user.smtp_user : ''"></a-input></td>
            </tr>
            <tr>
              <td><b>SMTP password</b></td>
              <td>
                <a-input v-model="password"></a-input>
                <small @click="showPassword(user.smtp_pass)" class="view-pass"
                  ><a-icon type="eye" /> View password</small
                >
              </td>
            </tr>
            <tr>
              <td><b>Connection</b></td>
              <td><a-input value="TLS"></a-input></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <a-modal
      title="Delete"
      :visible="deleting"
      :confirm-loading="confirmLoading"
      @ok="deleteAlias"
      @cancel="deleting = false"
    >
      <p>{{ modelText }}</p>
    </a-modal>
  </Layout>
</template>

<style>
.demo-loadmore-list {
  min-height: 350px;
}
.credential-table tbody td {
  padding: 10px;
}
</style>

<script>
import credential from "../mixins/credential";
import Layout from "./layout.vue";
export default {
  mixins: [credential],
  name: "SMTP",
  components: {
    Layout,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      modelText: "Are you sure you want to delete?",
      confirmLoading: false,
      searchVal: "",
      loading: true,
      loadingMore: false,
      showLoadingMore: true,
      formType: "add",
      smtps: [],
      filteredSmtps: [],
      isLoading: false,
      editing: false,
      adding: false,
      deleting: false,
      deleting_username: "",
      password: "*********",
      formSMTP: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    smtpsSearch() {
      if (!this.searchVal) {
        this.filteredSmtps = this.smtps;
        return;
      }
      this.loading = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.filteredSmtps = this.smtps.filter((e) =>
          e.username.includes(this.searchVal)
        );
        this.loading = false;
      }, 2000);
    },
    addNew() {
      this.adding = true;
      this.formSMTP = {
        username: "",
        password: "",
      };
    },
    submitAlias() {
      if (!this.formSMTP.username || !this.formSMTP.password) {
        this.$message.error("Please fill all inputs");
        return;
      }

      if (this.formType == "add") {
        this.addAlias();
        return;
      }
      this.updateAlias();
    },
    showPassword(pass) {
      if (!this.password.includes("*")) {
        this.password = "*********";
        return;
      }
      this.password = pass;
    },
    generatePassword(
      length = 20,
      wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
    ) {
      return Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map((x) => wishlist[x % wishlist.length])
        .join("");
    },
    insertGeneratePass() {
      const pass = this.generatePassword(10);
      let obj = {
        username: this.formSMTP.username,
        password: pass,
      };
      this.formSMTP = obj;
    },
    async addAlias() {
      const res = await fetch(
        this.api_url + "/domains/" + this.domain + "/credentials/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic api:" + this.api_key,
          },
          body: JSON.stringify(this.formSMTP),
        }
      );
      const data = await res.json();
      this.resetData();
      if (data.success) {
        this.$message.success("Successfully added!");
        return;
      }
      this.$message.error("Something went wrong!");
    },
    resetData() {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    editAlias(org_username) {
      this.editing = true;
      this.formType = "edit";
      const { username } = this.smtps.find((e) => e.username == org_username);
      this.formSMTP = { username };
      setTimeout(() => {
        document
          .querySelector("#formTop")
          .scrollIntoView({ behavior: "smooth" });
      }, 500);
    },
    async updateAlias() {
      const res = await fetch(
        this.api_url +
          "/domains/" +
          this.domain +
          "/credentials/" +
          this.formSMTP.username,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic api:" + this.api_key,
          },
          body: JSON.stringify({ password: this.formSMTP.password }),
        }
      );
      const data = await res.json();
      this.resetData();
      if (data.success) {
        this.$message.success("Successfully updated!");
        return;
      }
      this.$message.error("Something went wrong!");
    },
    openDeleteModal(username) {
      this.deleting = true;
      this.deleting_username = username;
    },
    async deleteAlias() {
      this.confirmLoading = true;
      this.modelText = "deleting...";
      const res = await fetch(
        this.api_url +
          "/domains/" +
          this.domain +
          "/credentials/" +
          this.deleting_username,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic api:" + this.api_key,
          },
        }
      );
      const data = await res.json();
      this.confirmLoading = false;
      this.resetData();
      if (data.success) {
        this.$message.success("Successfully deleted!");
        return;
      }
      this.$message.error("Something went wrong!");
    },
    onLoadMore() {
      this.loadingMore = true;
      this.getData((res) => {
        this.data = this.data.concat(res.results);
        this.loadingMore = false;
        this.$nextTick(() => {
          window.dispatchEvent(new Event("resize"));
        });
      });
    },
    async getData() {
      const res = await fetch(
        this.api_url + "/domains/" + this.domain + "/credentials/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic api:" + this.api_key,
          },
        }
      );
      const { credentials } = await res.json();
      this.smtps = credentials;
      this.loading = false;
    },
  },
};
</script>

<style>
#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.view-pass {
  cursor: pointer;
}
</style>
