var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"register"}},[_c('a-row',[_c('a-col',{attrs:{"md":16,"sm":12}},[_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("./../assets/menu.svg"),"alt":"menu"}})])]),_c('a-col',{attrs:{"md":8,"sm":12}},[_c('div',{staticClass:"register-box"},[_c('h4',[_vm._v("Register")]),_c('a-form',{ref:"register",staticClass:"register-form",attrs:{"id":"register-form","form":_vm.form},on:{"submit":_vm.handleRegister}},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","icon":"google-plus","block":""},on:{"click":_vm.loginWithGoogle}},[_vm._v("Google")])],1),_c('a-divider',[_vm._v("or")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'regemail',
          { rules: [{ required: true, message: 'Please input your email!' }] }
        ]),expression:"[\n          'regemail',\n          { rules: [{ required: true, message: 'Please input your email!' }] }\n        ]"}],attrs:{"placeholder":"Email"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'regpass',
          { rules: [{ required: true, message: 'Please input your Password!' }] }
        ]),expression:"[\n          'regpass',\n          { rules: [{ required: true, message: 'Please input your Password!' }] }\n        ]"}],attrs:{"type":"password","placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'regcompass',
          { rules: [{ required: true, message: 'Please input your Password!' },{ validator: _vm.confirmPass}] }
        ]),expression:"[\n          'regcompass',\n          { rules: [{ required: true, message: 'Please input your Password!' },{ validator: confirmPass}] }\n        ]"}],attrs:{"type":"password","placeholder":"Comfirm Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"register-form-button",attrs:{"type":"primary","html-type":"submit","loading":_vm.isLoading}},[_vm._v(" Register ")]),_vm._v(" Or "),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Login")])],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }