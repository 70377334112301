<template>
  <a-layout-sider collapsible v-model="collapsed">
    <div class="logo" />
    <a-menu theme="dark" :defaultSelectedKeys="['1']" mode="inline">
      <a-menu-item key="1">
        <router-link to="/"
          ><a-icon type="home" /> <span>Dashboard</span></router-link
        >
      </a-menu-item>
      <a-menu-item key="2">
        <router-link to="/aliases"
          ><a-icon type="mail" /> <span>Alias</span></router-link
        >
      </a-menu-item>
      <a-menu-item key="3" v-if="plan === 'S' || this.plan === 'G'">
        <router-link to="/smtps"
          ><a-icon type="usergroup-add" /> <span>SMTP</span></router-link
        >
      </a-menu-item>
      <a-menu-item key="4">
        <router-link to="/profile"
          ><a-icon type="file" /> <span>My Profile</span></router-link
        >
      </a-menu-item>
      <a-menu-item key="5" @click="signOut">
        <a-icon type="logout" />
        <span>Log out</span>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import credential from '../../mixins/credential';
import { auth } from "./../../firebase";

export default {
  mixins: [credential],
  data() {
    return {
      collapsed: false,
    };
  },
  methods: {
    signOut() {
      let self = this;
      const spinner = this.$message.loading("loading out..", 0);
      auth
        .signOut()
        .then(() => {
          localStorage.removeItem("current_user");
          setTimeout(spinner, 0);
          setTimeout(() => {
            self.$message.success("Successfully Logged Out!", 2);
          }, 1000);
          setTimeout(() => {
            this.$router.push("/login");
          }, 3000);
        })
        .catch((error) => {
          self.$message.error(error.message, 2);
        });
    },
  },
};
</script>