<template>
  <div id="app">
        <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
body{
  background-color:#f6f7f9 !important;
  font-family: 'Roboto', sans-serif !important;
  font-size:17px !important;
  font-weight:300;
  height:auto !important;
}
.banner{
    width:100%;
    height:100%;
}
.banner img{
    width:100%;
    height:auto;
}
#login .ant-divider-inner-text,#register .ant-divider-inner-text{
    color:#999;
}
</style>