<template>
  <Layout>

    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>My Profile</a-breadcrumb-item>
    </a-breadcrumb>
    <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
      <h1>My Profile</h1>
      <p>Welcome {{ auth.currentUser.email }}</p>
    </div>

  </Layout>

</template>

<script>
import { auth } from "./../firebase";
import Layout from "./layout.vue";
export default {
  name: "Home",
  components: {
    Layout
  },
  data() {
    return {
      isLoading: false,
      auth,
    };
  },
  methods: {},
};
</script>

<style>
#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
