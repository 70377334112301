<template>
    <h1>404 not found</h1>
</template>

<script>
export default {
    name:'NotFound'
}
</script>

<style>

</style>
