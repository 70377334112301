<template>
      <Layout>
 <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>Aliases</a-breadcrumb-item>
    </a-breadcrumb>
        <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
          <h1>Aliases</h1>
          <p><small>Aliases သည်မိမိတို့ gmail လိပ်စာရဲ့ နာမည်အပွားများဖြစ်ပြီး gmail ထဲကို forward လုပ်ပေးပါသည်</small></p>
            <a-divider />
            <p id="formTop" :style="{textAlign:'right'}">
              <a-button
              type="primary"
              @click="addNew"
              v-if="!editing || !adding"
              icon="plus"
              style="margin: 16px 0"
            >
              Add New
            </a-button>
            </p>
           <a-input @keyup="aliasesSearch" v-model="searchVal" placeholder="Searchy by Alias or Gmail" />
            <a-divider />

          <form
            id="aliasform"
            v-if="editing || adding"
            :style="{ maxWidth: '300px' }"
          >
            <a-input
              placeholder="Alias"
              v-model="formAlias.alias"
              :disabled="editing"
            /> <small>@{{ domain }}</small>
            <p style="margin: 16px 0">is forwarding to</p>
            <a-input placeholder="Foward" v-model="formAlias.forward" />
            <a-button
              @click="
                editing = false;
                adding = false;
              "
              icon="close"
              style="margin: 16px 16px 16px 0"
            >
              Cancel
            </a-button>
            <a-button
              @click="submitAlias"
              type="primary"
              icon="edit"
              style="margin: 16px 0"
            >
              {{ formType == "add" ? "Add" : "Change" }}
            </a-button>
          </form>

          <a-list
            class="demo-loadmore-list"
            :loading="loading"
            item-layout="horizontal"
            :data-source="filteredAliases.length > 0 ? filteredAliases : aliases"
            v-if="!editing || !adding"
          >
            <div
              v-if="showLoadingMore"
              slot="loadMore"
              :style="{
                textAlign: 'center',
                marginTop: '12px',
                height: '32px',
                lineHeight: '32px',
              }"
            >
              <a-spin v-if="loadingMore" />
              <!-- <a-button v-else @click="onLoadMore">
                        loading more
                    </a-button> -->
            </div>
            <a-list-item slot="renderItem" slot-scope="alias, index">
              <a slot="actions" @click="editAlias(alias.alias)">edit</a>
              <a slot="actions" @click="openDeleteModal(alias.alias)">delete</a>
              <!-- 
                    <a-list-item-meta
                        description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    >
                    </a-list-item-meta> -->
              <div>
                {{ index + 1 }}. <strong>{{ alias.alias }}</strong
                >@{{ domain }} is fowarding to <strong>{{ alias.forward }}</strong>
              </div>
            </a-list-item>
          </a-list>
        </div>
        <a-modal
          title="Delete"
          :visible="deleting"
          :confirm-loading="confirmLoading"
          @ok="deleteAlias"
          @cancel="deleting = false"
        >
          <p>{{ modelText }}</p>
        </a-modal>

     </Layout>
</template>

<style>
.demo-loadmore-list {
  min-height: 350px;
}
</style>

<script>
import credential from "./../mixins/credential";
import Layout from "./layout.vue";
export default {
  mixins: [credential],
  name: "Alias",
  components: {
    Layout
  },
  created() {
    this.current_user = localStorage.getItem("current_user")
      ? JSON.parse(localStorage.getItem("current_user"))
      : {};
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      searchVal:'',
      timer: undefined,
      current_user: {},
      modelText: "Are you sure you want to delete?",
      confirmLoading: false,
      loading: true,
      loadingMore: false,
      showLoadingMore: true,
      formType: "add",
      aliases: [],
      filteredAliases: [],
      isLoading: false,
      editing: false,
      adding: false,
      deleting: false,
      formAlias: {
        alias: "",
        forward: "",
      },
    };
  },
  methods: {
    aliasesSearch(){
      if(!this.searchVal){
          this.filteredAliases = this.aliases;
          return;
      }
      this.loading = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
          this.filteredAliases = this.aliases.filter(e=>{
             console.log(this.searchVal)
             if(e.alias.includes(this.searchVal) || e.forward.includes(this.searchVal)){
               return true;
             }
             return false;
          })
          this.loading = false;
      }, 2000)
    },
    addNew() {
      this.adding = true;
      this.formAlias = {
        alias: "",
        forward: "",
      };
    },
    submitAlias() {
      if (!this.formAlias.alias || !this.formAlias.forward) {
        this.$message.error("Please fill all inputs");
        return;
      }

      if (this.formType == "add") {
        this.addAlias();
        return;
      }
      this.updateAlias();
    },
    async addAlias() {
      const res = await fetch(
        `${this.api_url}/domains/${this.domain}/aliases/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic api:" + this.api_key,
          },
          body: JSON.stringify(this.formAlias),
        }
      );
      const data = await res.json();
      this.resetData();
      if (data.success) {
        this.$message.success("Successfully added!");
        return;
      }
      this.$message.error("Something went wrong!");
    },
    resetData() {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    editAlias(org_alias) {
      this.editing = true;
      this.formType = "edit";
      const { alias, forward } = this.aliases.find((e) => e.alias == org_alias);
      this.formAlias = { alias, forward };
      setTimeout(()=>{
        document.querySelector("#formTop").scrollIntoView({behavior:'smooth'})
      },500)
    },
    async updateAlias() {
      const res = await fetch(
        `${this.api_url}/domains/${this.domain}/aliases/${this.formAlias.alias}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic api:" + this.api_key,
          },
          body: JSON.stringify({ forward: this.formAlias.forward }),
        }
      );
      const data = await res.json();
      this.resetData();
      if (data.success) {
        this.$message.success("Successfully updated!");
        return;
      }
      this.$message.error("Something went wrong!");
    },
    openDeleteModal(alias) {
      this.deleting = true;
      this.deleting_alias = alias;
    },
    async deleteAlias() {
      this.confirmLoading = true;
      this.modelText = "deleting...";
      const res = await fetch(
        `${this.api_url}/domains/${this.domain}/aliases/${this.deleting_alias}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic api:" + this.api_key,
          },
        }
      );
      const data = await res.json();
      this.confirmLoading = false;
      this.resetData();
      if (data.success) {
        this.$message.success("Successfully deleted!");
        return;
      }
      this.$message.error("Something went wrong!");
    },
    onLoadMore() {
      this.loadingMore = true;
      this.getData((res) => {
        this.data = this.data.concat(res.results);
        this.loadingMore = false;
        this.$nextTick(() => {
          window.dispatchEvent(new Event("resize"));
        });
      });
    },
    async getData() {
      const res = await fetch(
        this.api_url + "/domains/" + this.domain + "/aliases/?limit=100",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic api:" + this.api_key,
          },
        }
      );
      const { aliases } = await res.json();
      this.aliases = aliases;
      this.loading = false;
    },
  },
};
</script>

<style>
#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
