<template>
  <a-layout id="components-layout-demo-side" style="min-height: 100vh">
    <Sidebar />
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0" />
      <a-layout-content style="margin: 0 16px">
        <slot></slot>
      </a-layout-content>
    <Footer />
    </a-layout>
  </a-layout>
</template>

<style>
.demo-loadmore-list {
  min-height: 350px;
}
</style>

<script>
import Sidebar from "./partials/sidebar.vue";
import Footer from "./partials/footer.vue";
export default {
  name: "Layout",
  components: {
    Sidebar,
    Footer
  }
};
</script>

<style>
#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
