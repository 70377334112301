<template>
     <a-layout-footer style="text-align: center">
        ©2021 | {{domain}} domain email panel
      </a-layout-footer>
</template>

<script>
import credential from '../../mixins/credential'
export default {
   mixins:[credential]
}
</script>
