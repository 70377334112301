<template>
<Layout>
  <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>Dashboard</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
                <h1>Welcome to {{domain}}!</h1>
        <a-spin v-if="loading" />
      <a-row v-if="!loading" :gutter="48">
        <a-col :sm="24" :md="12" :lg="8">
          <router-link to="/aliases">
            <div class="count-box pinky">
                <div>Aliases</div>
                <h1>{{aliases}}</h1>
            </div>
          </router-link>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8">
          <router-link to="/smtps">
           <div class="count-box greendy">
              <div>SMTPs</div>
              <h1>{{smtps}}</h1>
          </div>
          </router-link>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8">
          
        </a-col>
    </a-row>
         </div>
</Layout>
</template>

<script>
import credential from '../mixins/credential';
import Layout from "./layout.vue";
export default {
   name: 'Home',
   mixins: [credential],
   components:{
     Layout
   },
   data(){
     return{
       aliases:[],
       smtps:[],
       loading:false
     }
   },
  mounted() {
    this.getData();
  },
  methods:{
    async getData(){
     this.loading = true;
     await this.getSMTPs();
     await this.getAliases();
     this.loading = false;
    },
     async getAliases() {
      const res = await fetch(
        this.api_url + "/domains/" + this.domain + "/aliases/?limit=100",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic api:" + this.api_key,
          },
        }
      );
      const { total } = await res.json();
      this.aliases = total;
    },
    async getSMTPs() {
      const res = await fetch(
        this.api_url + "/domains/" + this.domain + "/credentials/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic api:" + this.api_key,
          },
        }
      );
      const { credentials } = await res.json();
      this.smtps = credentials.length;
     },
  }
}
</script>

<style>
.count-box{
   padding:20px;
   border-radius:10px;
   display:flex;
   color:#444;
   flex-direction: column;
   height:150px;
   align-items: center;
   justify-content: center;
   box-shadow:0 1px 10px #ddd;
}
.pinky{
  background:#ffe8fd;
}
.greendy{
  background:#eeffee;
}
</style>
