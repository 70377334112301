const current_user = localStorage.getItem('current_user') ? JSON.parse(localStorage.getItem('current_user')) : {api_token:'',domain:''};
export default {
    data(){
        return{
            user:current_user,
            domain:current_user.domain,
            plan:current_user.plan,
            api_key:current_user.api_key,
            api_url:'https://api.improvmx.com/v3'
        }
    }
}